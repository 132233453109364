import React from 'react';

export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What my peeps have said</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-content">
                      {d.text
                        ? d.text.map((t, i) => (
                            <p key={`${d.name}-${i}`}>{t}</p>
                          ))
                        : ''}
                    </div>
                    <div className="testimonial-name"> ~ {d.name} </div>
                    <div className="testimonial-title">
                      {d.title} @ {d.company}
                    </div>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
};
