import React from 'react';

export const Resume = (props) => {
  const renderEducation = (schools) => {
    return schools.map((school, i) => {
      return (
        <div key={`${school.name}-${i}`} className="col-md-6">
          <h4>{school.name}</h4>
          <p className="info">
            {school.degree} <span className="bullet">&bull;</span>{' '}
            <em className="date">{school.graduated}</em>
          </p>
          <p className="description">{school.description}</p>
        </div>
      );
    });
  };

  const renderExperience = (jobs) => {
    return jobs.map((job, i) => {
      return (
        <div key={`${job.company}-${i}`} className="col-sm-12">
          <h4>{job.company}</h4>
          <p className={`info ${job.subCompanies ? 'mb-0' : ''}`}>
            {job.title} <span className="bullet">&bull;</span>{' '}
            <em className="date">{job.years}</em>
          </p>
          {job.subCompanies ? renderSubCompanies(job.subCompanies) : ''}

          <section className="description">
            <h5>Summary of Duties</h5>
            <ul>{renderDutiesList(job.description.duties)}</ul>
          </section>

          <section className="description">
            <h5>Hard Skills</h5>
            <ul>{renderSkillsList(job.description.skills)}</ul>
          </section>
        </div>
      );
    });
  };

  const renderSubCompanies = (jobs) => {
    return jobs.map((job, i) => {
      return (
        <p className="info col-md-12" key={`${job.company}-${i}`}>
          {job.company} <span className="bullet">&bull;</span>{' '}
          <em className="date">{job.years}</em>
        </p>
      );
    });
  };

  const renderDutiesList = (duties) => {
    return duties.map((duty, i) => {
      return (
        <li key={`duty-${i + 1}`}>
          <span className="bullet">&bull;</span> {duty}
        </li>
      );
    });
  };

  const renderSkillsList = (skills) => {
    return skills.map((skill, i) => {
      return (
        <li key={`skill-${i + 1}`}>
          <span className="bullet">&bull;</span> <strong>{skill.title}</strong>{' '}
          {skill.text}
        </li>
      );
    });
  };

  return (
    <div id="resume">
      <div className="container">
        <div className="section-title text-center">
          <h2>Resume</h2>
        </div>
        {props.data ? (
          <div>
            <div className="row section-info">
              <div className="col-md-3">
                <h3 className="header">Education</h3>
              </div>

              <div className="col-md-9">
                <div className="row item">
                  <div className="col-md-12">
                    {renderEducation(props.data.education)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row section-info">
              <div className="col-md-3">
                <h3 className="header">Experience</h3>
              </div>

              <div className="col-md-9">
                <div className="row item">
                  <div className="col-md-12 resume-items">
                    {renderExperience(props.data.experience)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          'loading'
        )}
      </div>
    </div>
  );
};
