import './App.scss';

import React, { useEffect, useState } from 'react';

import { About } from './components/About';
// import { Team } from './components/Team';
import { Contact } from './components/Contact';
import { Header } from './components/Header';
import JsonData from './data/data.json';
import { Links } from './components/Links';
import { Navigation } from './components/Navigation';
import { Resume } from './components/Resume';
import SmoothScroll from 'smooth-scroll';
// import { Portfolio } from './components/Portfolio';
import { Testimonials } from './components/Testimonials';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Links data={landingPageData.Links} />
      <About data={landingPageData.About} />
      <Resume data={landingPageData.Resume} />
      {/* <Portfolio data={landingPageData.Portfolio} /> */}
      <Testimonials data={landingPageData.Testimonials} />
      {/* <Team data={landingPageData.Team} /> */}
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
