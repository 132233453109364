import React from 'react';

export const Links = (props) => {
  return (
    <div id="links" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Links</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-xs-4 col-md-4">
                  {' '}
                  <a
                    href={d.url}
                    title={d.name}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={d.icon}></i>
                  </a>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
