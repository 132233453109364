import React from 'react';

export const About = (props) => {
  const renderParagraph = (paragraphs) => {
    return paragraphs.map((paragraph, i) => {
      return <p key={`paragraph-${i}`}>{paragraph}</p>;
    });
  };

  const renderWhys = (whys) => {
    return whys.map((y, i) => {
      return (
        <li key={`why-${i}`}>
          <i className="icon fa fa-check"></i>
          {y}
        </li>
      );
    });
  };

  return (
    <div id="about">
      <div className="container">
        <div className="section-title text-center">
          <h2>About Fredrick</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              src={props.data?.profile}
              className="img-responsive"
              alt="Fredrick Jones"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              {props.data
                ? renderParagraph(props.data.paragraph)
                : 'loading...'}
              <h3>Why Choose Me?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? renderWhys(props.data.Why) : 'loading...'}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data ? renderWhys(props.data.Why2) : 'loading...'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
