import 'react-toastify/dist/ReactToastify.css';

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { send } from '@emailjs/browser';

const initialState = {
  from_name: '',
  from_email: '',
  message: '',
};
export const Contact = (props) => {
  const [toSend, setToSend] = useState(initialState);
  const year = new Date().getFullYear();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setToSend((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setToSend({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    const options = {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      theme: 'colored',
    };

    send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      toSend,
      process.env.REACT_APP_USER_ID
    ).then(
      (response) => {
        clearState();
        toast.success('SUCCESS: Email Sent!', options);
      },
      (err) => {
        toast.error('ERROR: Try Again!', options);
      }
    );
  };

  const renderLinks = (links) => {
    return links.map((link, i) => {
      return (
        <li key={`${link.name}-${i}`}>
          <a href={link.url} target="_blank" rel="noopener noreferrer">
            <i className={link.icon}></i>
          </a>
        </li>
      );
    });
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title text-center">
                <h2>Get In Touch</h2>
                <p>
                  Please fill out the form below to send me an email and I will
                  get back to you as soon as possible.
                </p>
              </div>

              <form name="sentMessage" validate="true" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="from_name"
                        name="from_name"
                        className="form-control"
                        placeholder="Name"
                        value={toSend.from_name}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="from_email"
                        name="from_email"
                        className="form-control"
                        placeholder="Email"
                        value={toSend.from_email}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    value={toSend.message}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>

                <div id="success"></div>

                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>{props.data ? renderLinks(props.data.links) : ''}</ul>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <ToastContainer />
          </div>
        </div>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>&copy; {year} Fredrick Jones</p>
        </div>
      </div>
    </div>
  );
};
